<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">审核管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabsChange">
          <el-tab-pane label="待审核" name="first">
            <registrationConsultationCh :name="activeName" ref="first" @get="clearParams" />
          </el-tab-pane>
          <el-tab-pane label="已通过" name="second">
            <registrationConsultationCh :name="activeName" ref="second" />
          </el-tab-pane>
          <el-tab-pane label="已驳回" name="three">
            <registrationConsultationCh :name="activeName" ref="three" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import registrationConsultationCh from "@/views/miniApp/mechanism/registrationConsultationCh.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ClassReview",
  components: {
    registrationConsultationCh
  },
  mixins: [List],
  data() {
    return {
      activeName: ""
    };
  },
  created() {
    this.activeName = this.$route.query.active || "first";
    // this.name = this.$route.query.name || "first";
  },
  mounted() {
    if (this.$route.query.active == "second") {
      this.$refs["second"].getData(
        -1,
        "/run/minicourse/auditPassListPage",
        "second"
      );
    } else if (this.$route.query.active == "three") {
      this.$refs["three"].getData(
        -1,
        "/run/minicourse/auditRejectListPage",
        "three"
      );
    } else {
      this.$refs["first"].getData(
        -1,
        "/run/minicourse/waitAuditListPage",
        "first"
      );
    }
  },
  methods: {
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
    tabsChange(e) {
      this.activeName = e.name;
      this.name = e.name;
      if (e.name == "second") {
        this.$refs.second.getData(1, "/run/minicourse/auditPassListPage", e.name);
      } else if (e.name == "three") {
        this.$refs.three.getData(1, "/run/minicourse/auditRejectListPage", e.name);
      } else {
        this.$refs.first.getData(1, "/run/minicourse/waitAuditListPage", e.name);
      }
      this.$refs[e.name].courseName = '';
      this.$refs[e.name].courseType = '';
      this.$refs[e.name].courseOrigin = '';
      this.$refs[e.name].compId = '';
      this.$refs[e.name].getData(-1);
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if (route.query.refresh) {
        this.$refs[route.query.active].getData(-1);
      }
    }
  }
  
};
</script>
<style lang="less">
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
