<template>
  <div>
    <div class="operationControl searchboxfl">
      <div class="searchbox">
        <div title="课程名称" class="searchboxItem ci-full">
          <span class="itemLabel">课程名称:</span>
          <el-input
            v-model="courseName"
            type="text"
            size="small"
            placeholder="请输入课程名称"
            clearable
          />
        </div>
        <div title="课程分类" class="searchboxItem ci-full">
          <span class="itemLabel">课程分类:</span>
          <el-cascader
            v-model="courseType"
            :options="miniCourseTypeList"
            :props="miniProps"
            clearable
            filterable
            size="small"
          ></el-cascader>
        </div>
        <div title="提交机构" class="searchboxItem ci-full">
          <span class="itemLabel">提交机构:</span>
          <el-select
            size="small"
            v-model="compId"
            remote
            :remote-method="getCompanyList"
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div title="是否平台资源" class="searchboxItem ci-full">
          <span class="itemLabel" style="min-width:7rem">是否平台资源:</span>
          <el-select
            size="small"
            v-model="courseOrigin"
            clearable
            placeholder="请选择是否平台资源"
          >
            <el-option
              v-for="item in platformResources"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="df" style="padding-right:1rem">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
          @sort-change="changeSort"
          :default-sort="{ prop: 'auditTime', order: 'ascending' }"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="100px"
          />
          <el-table-column
            label="课程名称"
            align="left"
            show-overflow-tooltip
            prop="courseName"
            width="200"
          />
          <el-table-column
            label="课程分类"
            align="left"
            show-overflow-tooltip
            prop="courseTypeName"
          />
          <el-table-column
            label="课件数量"
            align="left"
            show-overflow-tooltip
            prop="coursewareNum"
          />
          <el-table-column
            label="提交机构"
            align="left"
            show-overflow-tooltip
            prop="compName"
          />
          <el-table-column
            label="提交时间"
            align="left"
            show-overflow-tooltip
            prop="submitTime"
          />
          <el-table-column
            label="是否平台课程"
            align="left"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.courseOrigin == '20' ? '是':'否'
            }}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="130px"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="
                  doReview(
                    scope.row.courseId,
                    name
                  )
                "
                >{{name == 'second' ? '查看':'审核'}}</el-button
              >

              <!-- <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-show="name !== 'second'"
                @click="
                  projectPass(
                    scope.row.projectId,
                    scope.row.projectSource,
                    scope.row.auditState,
                    scope.row.trainTypeId,
                    scope.row.belongHebei,
                    name
                  )
                "
                >通过</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-show="name !== 'three'"
                :disabled="
                  scope.row.startStudy != false ||
                    (scope.row.projectSource == '20' &&
                      scope.row.auditState == '30')
                "
                @click="reject(scope.row.projectId, scope.row.auditState, name)"
                >驳回</el-button
              > -->
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "CourseToBeReviewed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      CompanyList: [],
      compId: "",
      names: "",
      courseName: "",
      courseOrigin:'',
      platformResources:[
        {
            value:'20',
            label:'是'
        },
        {
          value:'10',
          label:'否'
        }
      ],
      miniCourseTypeList:[], //培训类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
    };
  },
  computed: {},
  created() {
    this.init();
    // this.getData()
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getcourseTypeArrData();
    },
       //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    getData(pageNum, url, name) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
     if (this.courseName) {
       params.courseName = this.courseName
     }
     if (this.courseType) {
       params.courseType = this.courseType
     }
     if (this.courseOrigin) {
       params.courseOrigin = this.courseOrigin
     }
     if (this.compId) {
       params.compId = this.compId
     }

      if (!name) {
        let routerName = this.$route.query.active;
        if (routerName == "second") {
          url = "/run/minicourse/auditPassListPage";
        } else if (routerName == "three") {
          url = "/run/minicourse/auditRejectListPage";
        } else {
          url = "/run/minicourse/waitAuditListPage";
        }
        this.name = this.$route.query.active || "first";
      }

      this.doFetch({
        url: url,
        params,
        pageNum,
      },true,2);
    },
    doReview(courseId,name) {
      this.$router.push({
        path: "/web/miniApp/registrationConsultationChDetail",
        query: {
            courseId,
            active: name,
        },
      });
    },
    // projectPass(
    //   resourceId,
    //   projectSource,
    //   currentAuditState,
    //   trainTypeId,
    //   belongHebei,
    //   name
    // ) {
    //    this.name = name;
    //   if ((trainTypeId == "5" || trainTypeId == "16") && belongHebei) {
    //     this.resourceId = resourceId;
    //     this.trainTypeId = trainTypeId;
    //     this.projectSource = projectSource;
    //     this.currentAuditState = currentAuditState;
    //     this.getlevel(resourceId);
    //     this.dialoglevelVisible = true;
    //   } else {
    //     if (projectSource == "20") {
    //       this.$confirm(
    //         "外部班级审核通过之后不可以在驳回,该班级确定审核通过吗？",
    //         "审核申请",
    //         {
    //           confirmButtonText: "确定",
    //           cancelButtonText: "取消",
    //           confirmButtonClass: "confirmButtonClass",
    //         }
    //       )
    //         .then(() => {
    //           this.$post("/interface/cCSkillsTraing/openClassSure", {
    //             projectId: resourceId,
    //           })
    //             .then((ret) => {
    //               if (ret.status == 0) {
    //                 this.$message({
    //                   message: "审核成功",
    //                   type: "success",
    //                 });
    //                 if (name == "first") {
    //                   this.getData(-1, "/run/project/waitAuditListPage", name);
    //                 } else if (name == "second") {
    //                   this.getData(-1, "/run/project/auditPassListPage", name);
    //                 } else if (name == "three") {
    //                   this.getData(
    //                     -1,
    //                     "/run/project/auditRejectListPage",
    //                     name
    //                   );
    //                 }
    //               }
    //             })
    //             .catch((err) => {
    //               return;
    //             });
    //         })
    //         .catch(() => {
    //           return;
    //         });
    //       //郑州班级的验证临时关闭 -- 40
    //     } else if (projectSource == "40") {
    //       this.$post("/biz/heNan/pushProject/pushClassInfoApproval", {
    //         projectId: resourceId,
    //       })
    //         .then((ret) => {
    //           if (ret.status == 0) {
    //             this.$message({
    //               message: "审核成功",
    //               type: "success",
    //             });
    //             if (name == "first") {
    //               this.getData(-1, "/run/project/waitAuditListPage", name);
    //             } else if (name == "second") {
    //               this.getData(-1, "/run/project/auditPassListPage", name);
    //             } else if (name == "three") {
    //               this.getData(-1, "/run/project/auditRejectListPage", name);
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           return;
    //         });
    //     } else {
    //       const parmar = {
    //         resourceId,
    //         auditState: "30",
    //         currentAuditState,
    //       };
    //       this.$post("/run/project/modifyAuditPassState", {
    //         resourceId,
    //         auditState: "30",
    //         currentAuditState,
    //       })
    //         .then((ret) => {
    //           if (ret.status == "0") {
    //             this.$message({
    //               type: "success",
    //               message: "审核成功",
    //             });

    //             if (name == "first") {
    //               this.getData(-1, "/run/project/waitAuditListPage", name);
    //             } else if (name == "second") {
    //               this.getData(-1, "/run/project/auditPassListPage", name);
    //             } else if (name == "three") {
    //               this.getData(-1, "/run/project/auditRejectListPage", name);
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           return;
    //         });
    //     }
    //   }
    // },
    // reject(resourceId, currentAuditState, name) {
    //   this.resourceId = resourceId;
    //   this.currentAuditState = currentAuditState;
    //   this.dialogVisible = true;
    //   this.name = name;
    // },
    // doSure() {
    //   const parmar = {
    //     subsidyLevel: this.level,
    //     resourceId: this.resourceId,
    //     auditState: "30",
    //     currentAuditState: this.currentAuditState,
    //   };
    //   if (this.trainTypeId == "5" || this.trainTypeId == "16") {
    //     if (this.level == "") {
    //       this.$message.error("请选择级别");
    //       return;
    //     }
    //   }
    //   if (this.projectSource == "20") {
    //     this.$confirm(
    //       "外部班级审核通过之后不可以在驳回,该班级确定审核通过吗？",
    //       "审核申请",
    //       {
    //         confirmButtonText: "确定",
    //         cancelButtonText: "取消",
    //         confirmButtonClass: "confirmButtonClass",
    //       }
    //     )
    //       .then(() => {
    //         this.$post("/interface/cCSkillsTraing/openClassSure", {
    //           projectId: this.resourceId,
    //         })
    //           .then((ret) => {
    //             if (ret.status == 0) {
    //               this.$message({
    //                 message: "审核成功",
    //                 type: "success",
    //               });
    //               this.doCancle();
    //               if (this.name == "first") {
    //                 this.getData(-1, "/run/project/waitAuditListPage", this.name);
    //               } else if (this.name == "second") {
    //                 this.getData(-1, "/run/project/auditPassListPage", this.name);
    //               } else if (this.name == "three") {
    //                 this.getData(-1, "/run/project/auditRejectListPage", this.name);
    //               }
    //             }
    //           })
    //           .catch((err) => {
    //             return;
    //           });
    //       })
    //       .catch(() => {
    //         return;
    //       });
    //     //郑州班级的验证临时关闭 -- 40
    //   } else if (this.projectSource == "40") {
    //     this.$post("/biz/heNan/pushProject/pushClassInfoApproval", {
    //       projectId: this.resourceId,
    //     })
    //       .then((ret) => {
    //         if (ret.status == 0) {
    //           this.$message({
    //             message: "审核成功",
    //             type: "success",
    //           });
    //           this.doCancle();
    //           if (this.name == "first") {
    //             this.getData(-1, "/run/project/waitAuditListPage", this.name);
    //           } else if (this.name == "second") {
    //             this.getData(-1, "/run/project/auditPassListPage", this.name);
    //           } else if (this.name == "three") {
    //             this.getData(-1, "/run/project/auditRejectListPage", this.name);
    //           }
    //         }
    //       })
    //       .catch((err) => {
    //         return;
    //       });
    //   } else {
    //     this.$post("/run/project/modifyAuditPassState", parmar)
    //       .then((ret) => {
    //         if (ret.status == "0") {
    //           this.$message({
    //             type: "success",
    //             message: "审核成功",
    //           });
    //           this.doCancle();
    //           if (this.name == "first") {
    //             this.getData(-1, "/run/project/waitAuditListPage", this.name);
    //           } else if (this.name == "second") {
    //             this.getData(-1, "/run/project/auditPassListPage", this.name);
    //           } else if (this.name == "three") {
    //             this.getData(-1, "/run/project/auditRejectListPage", this.name);
    //           }
    //         }
    //       })
    //       .catch((err) => {
    //         return;
    //       });
    //   }
    // },
    // doCancle() {
    //   this.dialoglevelVisible = false;
    //   this.level = "";
    // },
    // sure() {
    //   if (!this.auditReason) {
    //     this.$message({
    //       message: "请输入驳回理由",
    //       type: "warning",
    //     });
    //     return false;
    //   }
    //   const parmar = {
    //     resourceId: this.resourceId,
    //     auditReason: this.auditReason,
    //     auditState: "40",
    //     currentAuditState: this.currentAuditState,
    //   };
    //   this.$post("/run/project/modifyAuditRejectState", parmar)
    //     .then((ret) => {
    //       if (ret.status == 0) {
    //         this.$message({
    //           message: "成功",
    //           type: "success",
    //         });
    //         this.dialogVisible = false;
    //         this.auditReason = "";
    //         console.log(this.name);
    //         if (this.name == "first") {
    //           this.getData(-1, "/run/project/waitAuditListPage", this.name);
    //         } else if (this.name == "second") {
    //           this.getData(-1, "/run/project/auditPassListPage", this.name);
    //         } else if (this.name == "three") {
    //           this.getData(-1, "/run/project/auditRejectListPage", this.name);
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       return;
    //     });
    // },
    // cancle() {
    //   this.auditReason = "";
    //   this.dialogVisible = false;
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  mounted: function() {},
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
    compId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
</style>
